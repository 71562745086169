/* $primary-color: red;
$primary-fontl
@mixin position-center-flex {
  position: absolute;
  display: flex;
  justify-content: cente
} */

.App {
  width: 100%;
  min-width: 300px;
  display: flex;
  justify-content: center;
  /* color: $primary-color; */
  position: absolute;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 100s linear;
  }
}
h1{
  color: black;
}
/*Agrego esta clases cuando pasan los 5s*/
.load{
  display: none;
}
/*Saco esta clases cuando pasan los 5s*/
.load2{
  width: 100vw;
  height: 100vh ;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Agrego esta clases cuando pasan los 5s*/
.appHeader {
  max-width: 1200px;
  min-width: 300px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  gap:40px;
  transition: all 5s ease-in-out !important;
  margin-top: 0vh;
}
/*Saco esta clases cuando pasan los 5s*/
.appHeader2{
  display: none;
  margin-top: 100vh;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*Deja los elementos centrados al -zoom*/
#root{
  display: flex;
  justify-content: center;
}

